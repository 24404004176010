<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('analytics.lang_salesTime')}}
        </div>
        <!--<div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
            <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t('generic.lang_Options') }}</h6>
              <button  class="dropdown-item" tabindex="0" type="button"
              >
                <v-icon class="dropdown-icon" color="warning">picture_as_pdf</v-icon>
                <span>Export PDF</span>
              </button>

              <button class="dropdown-item" tabindex="0" type="button" >
                <font-awesome-icon class="dropdown-icon success--text"
                                   icon="file-excel"/>
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>-->
      </v-card-title>
      <v-card-text class="mt-6">
        <v-row align="center">
          <v-col class="pb-0 mb-0" cols="12" sm="6">
            <BaseDateInput dense :label="$t('generic.lang_date')" outlined type="date" v-model="dayDate" hide-details/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12">
            <v-btn :disabled="this.loading" :loading="this.loading" @click="getSalesData" block
                   class="bg-primary text-light mx-auto">
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{this.$t('generic.lang_display')}}
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text class="pa-0">
                <apexchart type="bar" height="700" :options="chartOptions" :series="series"></apexchart>
                <div v-if="loading" class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                    :items="dataToShow"
                    :headers="dataTableHeaders"
                >
                  <template v-slot:item.price="{item}">
                    {{ item.price | currency }}
                  </template>
                  <template v-slot:item.total="{item}">
                    {{ item.total | currency }}
                  </template>

                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapGetters} from "vuex";
import moment from 'moment'
import VueApexCharts from "vue-apexcharts";
import {Events} from "@/plugins/events";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(
    faFileExcel
);

export default {
  name: "SalesTimeComponent",
  components:{
    'apexchart': VueApexCharts,
    'font-awesome-icon': FontAwesomeIcon,
    BaseDateInput
  },
  data(){
    return{
      series: [],
      chartOptions: {
          chart: {
            type: 'bar',
            height: 700,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          theme: {
            mode: this.$vuetify.theme.dark? 'dark' : 'light', 
          },
          labels:[],
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              borderRadius: 8,
              vertical: true,
            },
          },
          xaxis: {
            categories: [],
          },
          legend: {
            position: 'bottom',
          },
          fill: {
            opacity: 1
          }
      },
      loading:false,
      showTopChart:false,
      showTable:false,
      itemID:null,
      dayDate:null,
      dataTableHeaders:[
        {
          text:'ID',
          value:'id'
        },
        {
          text: this.$t('erp.lang_posItemName'),
          value:'name'
        },
        {
          text: this.$t('erp.lang_itemgroup_name'),
          value:'waregroup'
        },
        {
          text: this.$t('erp.lang_QTY'),
          value:'qty'
        },
        {
          text: this.$t('erp.lang_groosSalesPrice'),
          value:'price'
        },
        {
          text: this.$t('accounting.lang_totalBrut'),
          value:'total'
        },
        {
          text: this.$t('analytics.lang_bestHour'),
          value:'hour'
        },
        {
          text: this.$t('generic.lang_date'),
          value:'date'
        },
      ],
      barChartOptions:[],
      dataToShow:[],
    }
  },
  computed:{
    ...mapGetters({
      items: 'items/Items',
    }),
    filteredItems(){
      return _.orderBy(this.items.filter(item=>{
        return item.active===1;
      }),'name')
    },
  },
  methods:{
    async getSalesData() {
      this.loading = true;
      this.showTable=false;
      this.showTopChart=false
      this.series=[];
      this.dataToShow=[];
      this.axios.post("get/analytics/wareanalytics/itemSalesPerHour/", {
        startDate:moment(this.dayDate).startOf('day').unix(),
        endDate:moment(this.dayDate).endOf('day').unix(),
      }).then((res) => {

        if(res.data.success) {
          if(res.data.data.length ===0){
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_noDataToDisplay'),
              color: "warning"
            });
            return;
          }
          let data={};
          let products={}
          res.data.data.forEach(item=>{
            if(products.hasOwnProperty(item.itemName)){
              products[item.itemName].push(item)
            }else{
              products[item.itemName]=[];
              products[item.itemName].push(item)
            }
          });

          for(let product in products){
            let color = 'rgb(' +
                (Math.floor(Math.random()*255)) + ', ' +
                (Math.floor(Math.random()*255)) + ', ' +
                (Math.floor(Math.random()*255)) +
                ')';
            this.series.push({
              name:product,
              data:[],
              color:color,
            })
          }

         for(let i=0;i<24;i++){

           this.series.forEach(async serie=>{


             await products[serie.name].forEach( product=>{
               if(product.hour===i){

                 serie.data.push(parseInt(product.qty))
               }else if(serie.data.length===i){
                 serie.data.push(0)
               }
             })
           })
         }

          this.showTopChart=true;
          this.dataTableSetup(products);
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_dataLoading'),
            color: "success"
          });
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      });
    },
    dataTableSetup(items){

      for(let table in items){
        let row={};
        row.id=items[table][0].itemUID

        row.name=items[table][0].itemName
        row.waregroup=items[table][0].category
        row.price=items[table][0].totalPricePerItem
        row.qty=0;
        row.total=0;
        let tmp=0
        let tmpQTY=0
        items[table].forEach(item=>{
              row.qty+=parseInt(item.qty);
              row.total+=parseInt(item.qty)*parseFloat(item.totalPricePerItem)
          if(parseInt(item.qty)>tmpQTY){
            tmpQTY=parseInt(item.qty)
            tmp=item.hour
          }
        });
        row.date=this.dayDate
        row.hour=tmp;
        this.dataToShow.push(row)
      }

      this.showTable=true;
    },

  },
  mounted() {
    this.chartOptions.labels=[];
    //this.dayDate=moment.unix(Date.now()).format('yyyy-MM-dd')
    for(let hour = 0; hour < 24; hour++) {
      this.chartOptions.labels.push(hour+":00");
      this.chartOptions.xaxis.categories.push(hour+":00");
    }
  }
}
</script>

<style scoped>

</style>